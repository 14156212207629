<template>

    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="archMenu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">
                <v-icon
                    v-if="Object.keys(extendedTableFilter).length !== 0"
                    class="extend-filter"
                    v-tooltip.left-center="$t('Расширенный_поиск')"
                    left
                    @click="openExtendedFilterDialog"
                >
                    mdi-filter
                </v-icon>

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        >

            <CriteriasPanel
                :key="collection+status"
                :criterias="criterias"
                v-bind:value.sync="extendedTableFilter"
            />

            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />
        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            @change_loaddata_status="onLoadDataStatusChanged"
            ref="currentComponent"
        />

        <IncomingExFilterDlg 
            v-if="collection === 'Incomings' || collection === 'Dirords'"
            v-model="extendedTableFilter" 
            ref="IncomingExFilterDlgRef" 
        />

        <OutgoingExFilterDlg 
            v-if="collection === 'Outgoings' || collection === 'EuolAnswers'"
            v-model="extendedTableFilter" 
            ref="OutgoingExFilterDlgRef" 
        />

        <InnerExFilterDlg 
            v-if="collection === 'Inners' || collection === 'Ords'"
            v-model="extendedTableFilter" 
            ref="InnerExFilterDlgRef" 
        />

        <StatementsExFilterDlg 
            v-if="collection === 'EuolStatements' || collection === 'GovServices'"
            v-model="extendedTableFilter" 
            ref="StatementsExFilterDlgRef" 
        />

    </div>

</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import _ from 'lodash';
import Incomings from './lists/Incomings.vue'
import Outgoings from './lists/Outgoings.vue'
import EuolAnswers from './lists/EuolAnswers.vue'
import Inners from './lists/Inners.vue'
import Dirords from './lists/Dirords.vue'
import Ords from './lists/Ords.vue'
import EuolStatements from './lists/EuolStatements.vue'
import GovServices from './lists/GovServices.vue'
import Contracts from './lists/Contracts.vue'
import ExtendedFilterPanel from '@/components/ExtendedFilterPanel.vue';
import IncomingExFilterDlg from '@/components/dialogs/extended-filters/correspondence/incoming.vue';
import OutgoingExFilterDlg from '@/components/dialogs/extended-filters/correspondence/outgoing.vue';
import InnerExFilterDlg from '@/components/dialogs/extended-filters/correspondence/inner.vue';
import StatementsExFilterDlg from '@/components/dialogs/extended-filters/chancellary/statements.vue';

import CriteriasPanel from '@/components/CriteriasPanel.vue';

export default {
    name: "ArchiveProxy",
    components: {
        Toolbar,
        FilterPanel,
        Incomings,
        Outgoings,
        EuolAnswers,
        Inners,
        Dirords,
        Ords,
        EuolStatements,
        GovServices,
        Contracts,
        ExtendedFilterPanel,
        IncomingExFilterDlg,
        OutgoingExFilterDlg,
        InnerExFilterDlg,
        StatementsExFilterDlg,
        CriteriasPanel
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
            urls: [],
            dataloading:false,
            filterloading:false,
            collectionloading:false,
        }
    },
    computed : {
        ...mapGetters('archive', { getCurrentYear: 'getCurrentYear' }),
        filterloadingcomp:{
            get: function() { return this.filterloading; },
            set: function(newValue) { this.filtreloading = newValue; this.refreshOverlay(); }
        },
        collectionloadingcomp:{
            get: function() { return this.collectionloading; },
            set: function(newValue) { this.collectionloading = newValue; this.refreshOverlay(); }
        },
        archMenu() {
            return this.dataSource?.Data?.Menu ?? [];
            //return this.dataSource?.Data?.Menu?.filter(i => i.Action != 'Export') ?? [];
        },
        currentYear: {
            get: function() { return this.getCurrentYear; },            
            set: function(v) { this.setCurrentYear(v); }
        },
        collection: {
            get: function() {
                return this.$store.getters['archive/getCollection']
            },
            set: function(newValue) {
                this.$store.commit('archive/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['archive/getStatus']
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('archive/PUSH_STATUS', { collection: this.collection, value: newValue });

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['archive/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }                
                this.$store.commit('archive/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['archive/getExtendedFilter'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }   
                if (newValue)
                    this.$store.commit('archive/PUSH_EXTENDED_FILTER', { collection: this.collection, value: newValue } );
            }
        },
        criterias() {
            return this.$store.getters['archive/getCriterias'];
        }
    },
    methods: {        
        ...mapActions(['setOverlayVisible']),
        ...mapMutations('archive', { setCurrentYear: 'SET_CURRENTYEAR'}),
        onLoadDataStatusChanged(status){
            this.dataloading = status;
            this.refreshOverlay();    
        },
        refreshOverlay(){
            this.setOverlayVisible({ visible: this.dataloading || this.filterloading || this.collectionloading });
        },
        onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        async loadData() {
            this.collectionloadingcomp = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let type = '';

            switch (this.collection)
            {
                case 'EuolStatements':
                case 'GovServices':
                    type = 'CitizenStatements.EuolStatement'; break;

                case 'Contracts':
                    type = 'Documents.Correspondence.Contracts'; break;

                default:
                    type = 'Documents.Correspondence'; break;
            }

            let response = await httpAPI({
                url: `api/actions/collection?type=${ type }`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }
            
            this.collectionloadingcomp = false;
        },
        async loadFilters() {
            this.filterloadingcomp = true;
            let filterResponse = await httpAPI({
                url: `api/archive/filter?collection=${this.collection}&configuration=8&year=${0}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['auth/getUserInfo'])?.permissions ?? [];
                filterResponse.data.payload.filter.items = filterResponse.data.payload.filter.items.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                this.filterDataSource = filterResponse.data.payload;
            }
            this.filterloadingcomp = false;
        },
        async openExtendedFilterDialog() {
            try
            {
                switch(this.collection)
                {
                    case "Incomings":
                    case "Dirords":
                        await this.$refs.IncomingExFilterDlgRef.open();
                        break;

                    case "Outgoings":
                    case "EuolAnswers":
                        await this.$refs.OutgoingExFilterDlgRef.open();
                        break;

                    case "Inners":
                    case "Ords":
                        await this.$refs.InnerExFilterDlgRef.open();
                        break;

                    case "EuolStatements":
                    case "GovServices":
                        await this.$refs.StatementsExFilterDlgRef.open();
                        break;

                    default: break;
                }
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        },
        async Export(btn) {
            let exportURI = null;
            let filter = this.$refs.currentComponent.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;

            switch (btn.Type) {
                case 'CitizenStatements':
                    exportURI = `api/download/euolexport?type=${btn.Type}&param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;
                    break;

                default:
                    exportURI = `api/download/export?param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;
                    break;
            }

            if (process.env.NODE_ENV === "production")
                exportURI += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;

            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка ${btn.Type === 'CitizenStatements' ? "обращений" : "документов"} от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'Xls' });
            /*this.$store.dispatch('setOverlayVisible', { visible: true, text: 'Отчет_формируется...' });

            if (process.env.NODE_ENV === "production")
                exportURI += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                
            let response = await httpAPI({
                url: exportURI,
                method: 'GET',
                responseType: 'blob',
            });

            this.$store.dispatch('setOverlayVisible', { visible: false });

            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.urls.push(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Экспорт списка ${btn.Type === 'CitizenStatements' ? "обращений" : "документов"} от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}.xls` );
                document.body.appendChild(link);
                link.click();
            }*/
        }
    },
    watch: {
        collection() {
            this.loadData();
        }
    },
    async created() {
        await this.loadData();
    },
    beforeDestroy() {
        this.urls.forEach(url => URL.revokeObjectURL(url));
    }
}
</script>